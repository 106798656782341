:root
{
  --lightpurplecolor: #9a7cf3;
  --purplecolor: #7a5bd6;
}

.quiz_body {
  background-color:  rgb(29, 29, 29);
  min-height: 100vh;
  padding-bottom: 20px;
}
.auto_submit_span {
  color: #eb5757;
}
.quiz_heading {
  color: white;
  font-size: 90px;
  font-weight: bolder;
}

.quiz_heading span {
  color: var(--lightpurplecolor);
}

.btn {
  font-weight: bold;
  font-size: 17px;
  color: white;
}

.blue_btn {
  background-color: var(--purplecolor);
}

.pink_btn {
  background-color: #eb5757;
}

.blue_btn:hover {
  background-color: white;
  color: #3bc0d2;
}

.pink_btn:hover {
  background-color: white;
  color: #eb5757;
}

.progress-bar {
  background-color: #eb5757;
}
