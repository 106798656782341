.social-handles{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
}

.social-icons{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 30px;
    margin: 0px 5px;
    color: white;
    border-radius: 5px;
    text-decoration: none;
}


#ig{
    background-color: #ca4ea9;
}
#dc{
    background-color: #6c84d4;
}
#linkedin{
    background-color: #0c61bf;
}
#github{
    background-color: black;
}
#web{
    background-color: white;
    color: black;
}

@media only screen and (max-width: 600px) {
    .social-handles{
      flex-direction: column;
    }
    .social-icons{
        margin: 5px 0px;
    }
  }